<template>
    <main class="content">
      <div class="container">
        <div class="form-block">
          <header>
            <div class="logo">
              <img src="./../../assets/images/logo.png" alt="">
            </div>
          </header>
          <div class="form">
            <div class="icon">
              <img src="./../../assets/images/success.svg" alt="">
            </div>
            <p><span class="font-weight-medium">Account activation email sent!</span> <br>
            An account activation email has been send at your registered email address. Please click on the link provided in the email to activate your account.</p>
            <router-link to="/login" class="btn btn-primary alt">Back to login</router-link>
          </div>
          <footer>
            <div class="brand-logos d-flex">
              <div class="logo">
                <img src="./../../assets/images/bosch-logo.png" alt="">
              </div>
              <div class="logo">
                <img src="./../../assets/images/siemens-logo.png" alt="">
              </div>
              <div class="logo">
                <img src="./../../assets/images/neff.png" alt="">
              </div>
            </div>
            <p><a href="#" class="mr-3">Term of use</a><a href="#">Privacy Policy</a></p>
          </footer>
        </div>
      </div>
    </main>
</template>
<script>
export default {
  name: "SignupSuccessful",
  components: {},
  props: {},
  data: function () {
    return {
    };
  },
  methods: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>