var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "content" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "form-block" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "form" },
          [
            _vm._m(1),
            _vm._m(2),
            _c(
              "router-link",
              { staticClass: "btn btn-primary alt", attrs: { to: "/login" } },
              [_vm._v("Back to login")]
            )
          ],
          1
        ),
        _vm._m(3)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { src: require("./../../assets/images/logo.png"), alt: "" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("./../../assets/images/success.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "font-weight-medium" }, [
        _vm._v("Account activation email sent!")
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        " An account activation email has been send at your registered email address. Please click on the link provided in the email to activate your account."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", { staticClass: "brand-logos d-flex" }, [
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("./../../assets/images/bosch-logo.png"),
              alt: ""
            }
          })
        ]),
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("./../../assets/images/siemens-logo.png"),
              alt: ""
            }
          })
        ]),
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: { src: require("./../../assets/images/neff.png"), alt: "" }
          })
        ])
      ]),
      _c("p", [
        _c("a", { staticClass: "mr-3", attrs: { href: "#" } }, [
          _vm._v("Term of use")
        ]),
        _c("a", { attrs: { href: "#" } }, [_vm._v("Privacy Policy")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }